/*------------------------------------------------------------------
[VanillaTabs styles]
- Table of contents -

1. Global tabs styles
2. Horizontal tabs styles
3. Vertical tabs styles
4. Accordion / responsive styles
-------------------------------------------------------------------*/
$border-color: #ccc;
$bg-color: #fff;
$bg-secondary-color: #eee;

/*------------------------------------------------------------------
1. Global tabs styles
-------------------------------------------------------------------*/
.tabs {
	list-style: none;
	margin: 0 0 30px 0;
	padding: 0;
}

.tabs__nav_link {
	cursor: pointer;
	text-decoration: none;
}

.tabs__content {
	display: block;
	transition: opacity 0.2s;
}

.tabs.style__horizontal .tabs__content,
.tabs.style__vertical .tabs__content {
	height: 0;
	overflow: hidden;
	opacity: 0;
	visibility: hidden;
}

.tabs.style__horizontal .tabs__content.is__active,
.tabs.style__vertical .tabs__content.is__active,
.tabs__content.is__active {
	opacity: 1;
	height: auto;
	visibility: visible;
}

/*------------------------------------------------------------------
2. Horizontal tabs styles
-------------------------------------------------------------------*/
.tabs.style__horizontal .tabs__nav {
	position: relative;
	z-index: 2;
	display: flex;
	margin-bottom: -1px;
}

.tabs.style__horizontal .tabs__nav .tabs__nav_link {
	border: 1px solid $border-color;
	border-bottom: 0;
	margin: 0 3px;
	padding: 10px;
	background: $bg-secondary-color;
	display: block;
}

.tabs.style__horizontal .tabs__nav .tabs__nav_link.is__active {
	font-weight: bold;
	background: $bg-color;
}

.tabs.style__horizontal .tabs__content.is__active {
	border: 1px solid $border-color;
	background: $bg-color;
	padding: 18px;
	position: relative;
	z-index: 1;
}

.tabs.style__horizontal .tabs__content .tabs__nav_link {
	display: none;
}

/*------------------------------------------------------------------
3. Vertical tabs styles
-------------------------------------------------------------------*/
.tabs.style__vertical {
	display: flex;
	width: 100%;
}

.tabs.style__vertical .tabs__nav {
	flex: 0 0 150px;
}

.tabs.style__vertical .tabs__nav .tabs__nav_link {
	border: 1px solid $border-color;
	border-right: 0;
	margin: 3px 0;
	padding: 10px;
	background: $bg-secondary-color;
	display: block;
}

.tabs.style__vertical .tabs__nav .tabs__nav_link.is__active {
	font-weight: bold;
	background: $bg-color;
}

.tabs.style__vertical .tabs__content {
	flex-grow: 1;
	width: 0;
}

.tabs.style__vertical .tabs__content .tabs__nav_link {
	display: none;
}

.tabs.style__vertical .tabs__content.is__active {
	border: 1px solid $border-color;
	background: $bg-color;
	padding: 18px;
	position: relative;
	z-index: 1;
	width: auto;
}

/*------------------------------------------------------------------
4. Accordion / responsive styles
-------------------------------------------------------------------*/
.tabs.style__accordion,
.tabs.is__responsive {
	display: block;
}
.tabs.style__accordion .tabs__nav,
.tabs.is__responsive .tabs__nav {
	display: none;
}

.tabs.style__accordion .tabs__nav_link,
.tabs.is__responsive .tabs__nav_link,
.tabs.style__accordion .tabs__content .tabs__nav_link,
.tabs.is__responsive .tabs__content .tabs__nav_link {
	display: block;
	padding: 18px;
	margin: -18px;
}

.tabs.style__accordion .tabs__nav_link.is__active,
.tabs.is__responsive .tabs__nav_link.is__active {
	font-weight: bold;
	margin-bottom: 0;
}

.tabs.style__accordion .tabs__content,
.tabs.is__responsive .tabs__content {
	border: 1px solid $border-color;
	padding: 18px;
	background: $bg-color;
	margin: 0 0 15px 0 0;
	display: block;
	height: auto;
	flex: none;
	opacity: 1;
	visibility: visible;
	overflow: auto;
	width: auto;
}

.tabs.style__accordion .tabs__content_wrapper,
.tabs.is__responsive .tabs__content_wrapper { 
	height: 0;
	transform: scaleY(0);
	overflow: hidden;
	transition: transform 0.2s;
	transform-origin: top left;
}

.tabs.style__accordion .tabs__content.is__active .tabs__content_wrapper,
.tabs.is__responsive .tabs__content.is__active .tabs__content_wrapper {
	height: auto;
	transform: scaleY(1);
}